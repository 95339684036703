import {
  GET_PROJECT,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  DELETE_PROJECT,
  GET_PROJECTS_SCENARIO_REQUEST,
  GET_PROJECTS_SCENARIO_SUCCESS,
  GET_PROJECTS_SCENARIO_ERROR,
  CLEAR_PROJECTS_SCENARIO,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  GET_PROJECT_WITH_COLLABS_AND_ROLES_SUCCESS,
  UPDATE_PROJECT_COLLABORATORS,
  GET_PROJECTS_DASHBOARD_REQUEST,
  GET_PROJECTS_DASHBOARD_SUCCESS,
  GET_PROJECTS_DASHBOARD_ERROR,
  GET_ACTIVE_PROJECTS_REQUEST,
  GET_ACTIVE_PROJECTS_SUCCESS,
  GET_ACTIVE_PROJECTS_ERROR,
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_ERROR,
  UPDATE_PROJECTS_ROW_INDEX_REQUEST,
  UPDATE_PROJECTS_ROW_INDEX_SUCCESS,
  UPDATE_PROJECTS_ROW_INDEX_ERROR,
  CREATE_PROJECT_WITH_PROGRAM_SUCCESS,
  DELETE_PROGRAM,
  APPEND_PROJECTS_TO_PROGRAM,
  CREATE_PROGRAM,
  GET_PROGRAM_WITH_GANTT_DATA,
  REFETCH_PROGRAM_GANTT_DATA,
  UPDATE_PROGRAMS_REQUEST,
  UPDATE_PROGRAMS_ERROR,
  GET_PROGRAM_WITH_TIMELINE_DATA,
  GET_ALL_EXISTING_SUB_CODES,
  UPDATE_CLOSED_STATUS_OF_SUB_CODES,
  SET_ACTIVE_PROJECT,
  SWAP_SCENARIO_ON_PROGRAM_GANTT_DATA,
  ADD_PROGRAM_IF_DOES_NOT_EXIST,
  ADD_PROJECT,
  PROPAGATE_PROJECT_ROLE,
  UPDATE_PROJECT_WORK_PACKAGES,
} from "../types";
import produce from "immer";
import { updateItemInArray } from "../../utils/FilterUtils/FilterUtils";
import { extractFieldWithRecursion } from "../../utils/ObjectUtils/ObjectUtils";
import { extractIdsOfTasksTree } from "../../utils/ScheduleActivityUtils/ScheduleActivityUtils";

const initialState = {
  items: [],
  roles: [],
  collaborators: [],
  permissions: [],
  activeItems: [],
  item: {},
  dashboard: [],
  programs: [],
  projectsScenario: {},
  isFetchingTimeline: false,
  isFetchingDashboard: false,
  isUpdating: false,
  isFetching: false,
  isActiveItemsFetching: false,
  isProgramsFetching: false,
  currentProgram: {
    inlineData: {},
    timelineData: [],
    metrics: {},
  },
  allSubCodes: [],
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        items: [...state.items, payload],
        isFetching: false,
        error: "",
      };
    case CREATE_PROJECT_ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    case CREATE_PROGRAM:
      return {
        ...state,
        items: [...state.items, payload],
        programs: [...state.programs, payload],
        isFetching: false,
        error: "",
      };
    case CREATE_PROJECT_WITH_PROGRAM_SUCCESS:
      return produce(state, (draftState) => {
        const addedProgram = payload[0];
        draftState.isFetching = false;
        draftState.items = [...draftState.items, ...payload];
        draftState.programs = [...draftState.programs, addedProgram];
        draftState.error = "";
      });
    case ADD_PROGRAM_IF_DOES_NOT_EXIST:
      const programAlreadyExists = state.programs.some(
        (program) => program.id === payload?.id
      );
      // not mutating the state if program already exists
      if (programAlreadyExists) return state;

      return produce(state, (draftState) => {
        const newItems = draftState.items.slice();
        const newPrograms = draftState.programs.slice();
        newItems.push(payload);
        newPrograms.push(payload);
        draftState.items = newItems;
        draftState.programs = newPrograms;
      });
    case ADD_PROJECT:
      return produce(state, (draftState) => {
        const newItems = draftState.items.slice();
        newItems.push(payload);
        draftState.items = newItems;
      });
    case GET_PROGRAM_WITH_GANTT_DATA:
      return produce(state, (draftState) => {
        draftState.currentProgram.inlineData = payload.inlineData;
        // todo : add usages
        draftState.programs = payload.programs;
        draftState.isProgramsFetching = false;
      });
    case GET_PROGRAM_WITH_TIMELINE_DATA:
      return produce(state, (draftState) => {
        draftState.currentProgram.timelineData = payload.timelineData;
        draftState.programs = payload.programs;
        draftState.isProgramsFetching = false;
      });
    case UPDATE_PROGRAMS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case UPDATE_PROGRAMS_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case REFETCH_PROGRAM_GANTT_DATA:
      return produce(state, (draftState) => {
        draftState.currentProgram.inlineData = payload;
        draftState.isUpdating = false;
      });
    case SWAP_SCENARIO_ON_PROGRAM_GANTT_DATA:
      return produce(state, (draftState) => {
        const {
          eventsData,
          resourcesData,
          assignmentsData,
          dependenciesData,
          startDate,
          endDate,
        } = draftState.currentProgram.inlineData;
        const program = eventsData[0];
        const oldProject = program.children.find(
          (project) => project.scenarioId === payload.oldScenarioId
        );
        const newProject = payload.inlineData.eventsData[0].children[0];
        const idsToDelete = extractIdsOfTasksTree([oldProject]);
        const existingResourceIds = resourcesData.map(
          (resource) => resource.id
        );
        const keptAssignments = assignmentsData.filter(
          (assignment) => !idsToDelete.includes(assignment.event)
        );
        const keptDependencies = dependenciesData.filter(
          (dependency) =>
            !idsToDelete.includes(dependency.from) &&
            !idsToDelete.includes(dependency.to)
        );
        const addedResources = payload.inlineData.resourcesData.filter(
          (resource) => !existingResourceIds.includes(resource.id)
        );
        addedResources.push(...resourcesData);
        keptAssignments.push(...payload.inlineData.assignmentsData);
        keptDependencies.push(...payload.inlineData.dependenciesData);
        program.children = program.children.map((child) =>
          child.scenarioId === payload.oldScenarioId ? newProject : child
        );
        const startDateHasToChange = startDate > payload.inlineData.startDate;
        const newStartDate = startDateHasToChange
          ? payload.inlineData.startDate
          : startDate;
        const endDateHasToChange = endDate < payload.inlineData.endDate;
        const newEndDate = endDateHasToChange
          ? payload.inlineData.endDate
          : endDate;
        draftState.currentProgram.inlineData = {
          eventsData: [program],
          resourcesData: addedResources,
          dependenciesData: keptDependencies,
          assignmentsData: keptAssignments,
          startDate: newStartDate,
          endDate: newEndDate,
        };
      });
    case GET_PROJECTS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.items = [];
        draftState.error = "";
      });
    case GET_PROJECTS_SUCCESS:
      return produce(state, (draftState) => {
        const mappedProjects = payload.data.map(
          ({ project, start, finish }) => {
            if (!start || !finish) return project;
            const startDate = new Date(start);
            const finishDate = new Date(finish);
            return { ...project, start: startDate, finish: finishDate };
          }
        );

        draftState.isFetching = false;
        draftState.error = "";
        draftState.items = mappedProjects;

        if (payload.currentProjectId) {
          draftState.item =
            mappedProjects.find((p) => p.id === +payload.currentProjectId) ||
            {};
        }
      });
    case GET_PROJECTS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case GET_PROJECT_WITH_COLLABS_AND_ROLES_SUCCESS:
      return {
        ...state,
        ...payload,
        items: payload.projects.map(({ project, start, finish, ...rest }) => {
          if (!start || !finish) return project;

          const startDate = new Date(start);
          const finishDate = new Date(finish);
          return { ...project, start: startDate, finish: finishDate, ...rest };
        }),
        isFetching: false,
      };
    case UPDATE_PROJECTS_ROW_INDEX_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
        draftState.error = "";
      });
    case UPDATE_PROJECTS_ROW_INDEX_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        payload.forEach((updatedItem) => {
          const index = draftState.items.findIndex(
            (item) => item.id === updatedItem.id
          );
          draftState.items[index] = updatedItem;
        });
      });
    case UPDATE_PROJECTS_ROW_INDEX_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case APPEND_PROJECTS_TO_PROGRAM:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (payload.projectIds.includes(item.id))
            item.programId = payload.programId;
          return item;
        });
      });
    case GET_ACTIVE_PROJECTS_REQUEST:
      return {
        ...state,
        isActiveItemsFetching: true,
        activeItems: [],
        error: "",
      };
    case GET_ACTIVE_PROJECTS_SUCCESS:
      return {
        ...state,
        activeItems: payload,
        isActiveItemsFetching: false,
      };
    case GET_ACTIVE_PROJECTS_ERROR:
      return {
        ...state,
        isActiveItemsFetching: false,
        error: payload,
      };
    case GET_PROJECTS_SCENARIO_REQUEST:
      return {
        ...state,
        isFetchingTimeline: true,
        projectsScenario: {},
        error: "",
      };
    case GET_PROJECTS_SCENARIO_SUCCESS:
      return {
        ...state,
        projectsScenario: payload,
        isFetchingTimeline: false,
      };
    case GET_PROJECTS_SCENARIO_ERROR:
      return {
        ...state,
        isFetchingTimeline: false,
        projectsScenario: {},
        error: payload,
      };
    case UPDATE_PROJECT_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
        draftState.error = "";
      });
    case UPDATE_PROJECT_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.items = updateItemInArray(draftState.items, payload);
      });
    case UPDATE_PROJECT_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });

    case UPDATE_PROJECT_COLLABORATORS:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((project) =>
          project.id === payload.id ? { ...project, ...payload } : project
        );
      });
    case GET_ALL_EXISTING_SUB_CODES:
      return produce(state, (draftState) => {
        draftState.allSubCodes = payload;
      });
    case GET_PROJECT:
      return {
        ...state,
        item: payload,
      };
    case CLEAR_PROJECTS_SCENARIO:
      return {
        ...state,
        projectsScenario: {},
      };
    case DELETE_PROJECT:
      return {
        ...state,
        items: state.items.filter((project) => project.id !== payload),
      };
    case DELETE_PROGRAM:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.filter((item) =>
          item.typeOfProject ? item.programId !== payload : item.id !== payload
        );
        draftState.programs = draftState.programs.filter(
          (program) => program.id !== payload
        );
      });
    case GET_PROJECTS_DASHBOARD_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingDashboard = true;
        draftState.dashboard = [];
      });
    case GET_PROJECTS_DASHBOARD_SUCCESS:
      return produce(state, (draftState) => {
        draftState.dashboard = payload;
        draftState.isFetchingDashboard = false;
      });
    case GET_PROJECTS_DASHBOARD_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingDashboard = false;
        draftState.error = payload;
      });
    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        isProgramsFetching: true,
        programs: [],
        error: "",
      };
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        programs: payload,
        isProgramsFetching: false,
      };
    case GET_PROGRAMS_ERROR:
      return {
        ...state,
        isProgramsFetching: false,
        error: payload,
      };
    case UPDATE_CLOSED_STATUS_OF_SUB_CODES:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (!item.typeOfProject) return item;

          item.workPackages.forEach((wp) => {
            if (wp.id in payload) {
              wp.closed = payload[wp.id];
            }
          });
          return item;
        });
      });
    case SET_ACTIVE_PROJECT:
      return produce(state, (draftState) => {
        draftState.item = draftState.items.find((item) => item.id === payload);
      });
    case PROPAGATE_PROJECT_ROLE:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (item.id in payload) {
            item.projectCollaborators = {
              ...item.projectCollaborators,
              ...payload[item.id],
            };
          }
          return item;
        });
      });
    case UPDATE_PROJECT_WORK_PACKAGES:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (item.id === payload.id) item.workPackages = payload.workPackages;
          return item;
        });
      });
    default:
      return state;
  }
}
