import { modifyActivity } from "../../utils/DependencyUtils/DependencyUtils";
import {
  updateItemInArray,
  updateItemNameInArray,
} from "../../utils/FilterUtils/FilterUtils";
import { updateProgressOfActivities } from "../../utils/ActivityUtils/ActiivtyUtils";
import {
  GET_ACTIVITY,
  GET_ACTIVITIES,
  DELETE_ACTIVITY,
  MOVE_ACTIVITY_REQUEST,
  MOVE_ACTIVITY_SUCCESS,
  MOVE_ACTIVITY_ERROR,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_ERROR,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_ERROR,
  UPDATE_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_DEPS_REQUEST,
  GET_ACTIVITIES_DEPS_SUCCESS,
  GET_ACTIVITIES_DEPS_ERROR,
  CREATE_ACTIVITY_DEPS_SUCCESS,
  UPDATE_ACTIVITY_DATA_REQUEST,
  UPDATE_ACTIVITY_DATA_SUCCESS,
  UPDATE_ACTIVITY_DATA_ERROR,
  UPDATE_ACTIVITY_PROGRESS_REQUEST,
  UPDATE_ACTIVITY_PROGRESS_SUCCESS,
  UPDATE_ACTIVITY_PROGRESS_ERROR,
  UPDATE_PROGRESS_OF_ACCUM_ACTIVITIES,
  CLEAR_ACCUM_ACTIVITIES,
  GET_ALL_ACTIVITIES_REQUEST,
  GET_ALL_ACTIVITIES_SUCCESS,
  GET_ALL_ACTIVITIES_ERROR,
  UPDATE_ACTIVITY_NAME_REQUEST,
  UPDATE_ACTIVITY_NAME_SUCCESS,
  UPDATE_ACTIVITY_NAME_ERROR,
  REFETCH_ACTIVITY,
} from "../types";

const initialState = {
  activities: [],
  activitiesWithDeps: [],
  activity: {},
  accumActivities: [],
  isFetching: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_ACTIVITY_SUCCESS:
      const tmp = state.activitiesWithDeps;
      tmp.unshift(modifyActivity(action.payload));
      return {
        ...state,
        isFetching: false,
        activities: [...state.activities, action.payload],
        accumActivities: [...state.accumActivities, action.payload],
        activitiesWithDeps: tmp,
      };

    case CREATE_ACTIVITY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ACTIVITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        activities: [],
      };
    case GET_ACTIVITIES_SUCCESS:
      const _accumActivities = state.accumActivities;
      (action.payload || []).forEach((newAct) => {
        if (!_accumActivities.find((oldAct) => oldAct.id === newAct.id)) {
          _accumActivities.push(newAct);
        }
      });

      return {
        ...state,
        isFetching: false,
        activities: action.payload,
        accumActivities: _accumActivities,
      };
    case GET_ACTIVITIES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ALL_ACTIVITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ALL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accumActivities: action.payload,
      };
    case GET_ALL_ACTIVITIES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ACTIVITIES_DEPS_REQUEST:
      return {
        ...state,
        isFetching: true,
        activitiesWithDeps: [],
      };
    case GET_ACTIVITIES_DEPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activitiesWithDeps: action.payload,
      };
    case GET_ACTIVITIES_DEPS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
      };
    case DELETE_ACTIVITY:
      return {
        ...state,
        activities: state.activities.filter(
          (activity) => activity.id !== action.payload
        ),
        activitiesWithDeps: state.activitiesWithDeps.filter(
          (activity) => activity.id !== action.payload
        ),
        accumActivities: state.accumActivities.filter(
          (activity) => activity.id !== action.payload
        ),
      };
    case MOVE_ACTIVITY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case MOVE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activities: updateItemInArray(state.activities, action.payload),
        accumActivities: updateItemInArray(
          state.accumActivities,
          action.payload
        ),
      };
    case MOVE_ACTIVITY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_ACTIVITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activities: action.payload,
      };
    case UPDATE_ACTIVITY_DATA_REQUEST:
      return state;
    case UPDATE_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        activities: updateItemInArray(state.activities, action.payload),
        accumActivities: updateItemInArray(
          state.accumActivities,
          action.payload
        ),
      };
    case UPDATE_ACTIVITY_DATA_ERROR:
      return state;
    case UPDATE_ACTIVITY_NAME_REQUEST:
      return state;
    case UPDATE_ACTIVITY_NAME_SUCCESS:
      return {
        ...state,
        activities: updateItemNameInArray(state.activities, action.payload),
        accumActivities: updateItemNameInArray(
          state.accumActivities,
          action.payload
        ),
      };
    case UPDATE_ACTIVITY_NAME_ERROR:
      return state;
    case UPDATE_ACTIVITY_PROGRESS_REQUEST:
      return state;
    case UPDATE_ACTIVITY_PROGRESS_SUCCESS:
      return {
        ...state,
        activities: state.activities.map((item) => {
          if (
            item.id === action.payload.id ||
            item.parentId === action.payload.id
          ) {
            item.progress = action.payload.progress;
          }
          return item;
        }),
        accumActivities: state.accumActivities.map((item) => {
          if (
            item.id === action.payload.id ||
            item.parentId === action.payload.id
          ) {
            item.progress = action.payload.progress;
          }
          return item;
        }),
      };
    case UPDATE_ACTIVITY_PROGRESS_ERROR:
      return state;
    case UPDATE_PROGRESS_OF_ACCUM_ACTIVITIES:
      return {
        ...state,
        accumActivities: updateProgressOfActivities(
          state.accumActivities,
          action.payload.id,
          action.payload.progress,
          action.payload.children
        ),
      };
    case CLEAR_ACCUM_ACTIVITIES:
      return {
        ...state,
        accumActivities: [],
      };
    case REFETCH_ACTIVITY:
      return {
        ...state,
        accumActivities: state.accumActivities.map((activity) =>
          activity.id == action.payload.id ? action.payload : activity
        ),
        activities: state.activities.map((activity) =>
          activity.id == action.payload.id ? action.payload : activity
        ),
      };
    default:
      return state;
  }
}
/*
   activities: state.activities.map(activity => {
            console.log(action.payload);
          const found = action.payload.find(updatedActivities => updatedActivities.id === activity.id);
          if (found) {
              return Object.assign(activity, found);
          }
          return activity;
        }),*/
