import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  GET_USER_PROJECTS_PERMISSIONS,
} from "../types";

const initialState = {
  user: {},
  projectPermissions: {},
  portfolioId: undefined,
  isFetching: false,
  error: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case GET_CURRENT_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_USER_PROJECTS_PERMISSIONS:
      return {
        ...state,
        projectPermissions: payload,
        isFetching: false,
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
        error: "",
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
};
