import {
  UPDATE_REVIEW_DATE_REQUEST,
  UPDATE_REVIEW_DATE_SUCCESS,
  UPDATE_REVIEW_DATE_ERROR,
  GET_DETAILS_REQUEST,
  GET_DETAILS_SUCCESS,
  GET_DETAILS_ERROR,
  UPDATE_WORKLOAD_EDITION_REQUEST,
  UPDATE_WORKLOAD_EDITION_SUCCESS,
  UPDATE_WORKLOAD_EDITION_ERROR,
  GET_PORTFOLIO_SUCCESS,
  UPDATE_DETAILS_REQUEST,
  UPDATE_DETAILS_SUCCESS,
  UPDATE_DETAILS_ERROR,
  GET_PORTFOLIO_OPTIONS,
  ADD_PORTFOLIO_OPTION,
} from "../types";

const initialState = {
  details: {},
  currentScenarioName: "",
  options: [],
  loading: false,
  error: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PORTFOLIO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        details: payload,
      };
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        details: payload,
        name: payload.name,
      };
    case GET_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_REVIEW_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REVIEW_DATE_SUCCESS:
      return {
        ...state,
        loading: false,

        error: "",
      };
    case UPDATE_REVIEW_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_WORKLOAD_EDITION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_WORKLOAD_EDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        details: {
          ...state.details,
          workloadEditionEnabled: payload,
        },
        error: "",
      };
    case UPDATE_WORKLOAD_EDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case UPDATE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DETAILS_SUCCESS:
      const { startDate, finishDate } = state.details;
      if (startDate && finishDate) {
        payload.startDate = startDate;
        payload.finishDate = finishDate;
      }
      return {
        ...state,
        loading: false,
        details: payload,
      };
    case UPDATE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_PORTFOLIO_OPTIONS:
      return {
        ...state,
        loading: false,
        options: payload,
      };
    case ADD_PORTFOLIO_OPTION:
      const newOptions = state.options.slice();
      newOptions.push(payload);
      return { ...state, options: newOptions };
    default:
      return state;
  }
}
